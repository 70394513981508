<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Admins
			.widget-body
				
				.row
					.col-sm-8.form-inline
						.input-group.input-group-inline.input-group-rounded
							input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
						| &nbsp;
						.form-group
							button.btn.btn-primary(type="button", @click="loadData()") Go
					.col-sm-4.text-right
						router-link.btn.btn-success(:to="{name:'admin-add'}")
							i.la.la-plus
							| Add
				
				p.clearfix &nbsp;
				
				.ov-table-wrapper
					table.table.table-bordered
						thead
							tr
								th ID
								th Username
								th Actions
						tbody
							tr(v-if="records.length===0")
								td(colspan="3") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td {{ r.username }}
								td.actions
									router-link.btn.btn-sm.btn-secondary(:to="{name:'admin-edit', params: {id:r.id}}")
										i.la.la-pencil
									| &nbsp;
									a.btn.btn-sm.btn-secondary(href="")
										i.la.la-trash
					paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<script>
export default {
  name: 'AdminList',
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 10,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        keyword: '',
      },
      records: [],
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
      };
      try {
        let resp = await this.$ovReq.get('admin/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
      // this.$http.get('admin/getList', {params})
      // 	.then(
      // 		resp => {
      // 			this.records = resp.body.data.records;
      // 			this.paginate.total = resp.body.data.total;
      // 			this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      // 			this.busy = false;
      // 		},
      // 		resp => {
      // 			console.error(resp);
      // 		}
      // 	);
      // this
    },
  },
  created() {
    this.loadData();
  },
};
</script>
